import React from 'react';
import {
  Box,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

const FocusAreaImage = ({ src, alt }) => (
  <Flex>
    <Image
      src={src}
      alt={alt}
      objectFit="cover"
      rounded="md"
    />
  </Flex>
);

const FocusAreaText = ({ heading, text }) => (
  <Stack spacing={useBreakpointValue({ base: 2, md: 4 })}>
    <Text textStyle="h2">{heading}</Text>
    <Text color="gray.600" textStyle="body">{text}</Text>
  </Stack>
);

export const FocusArea = ({
  heading, description, imageSrc, isTextOnLeft,
}) => {
  const isTextOnLeftAdj = useBreakpointValue({ base: true, md: isTextOnLeft });
  return (
    <Container maxW="5xl" py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        {(isTextOnLeftAdj) ? (
          <FocusAreaText heading={heading} text={description} />)
          : (<FocusAreaImage src={imageSrc} alt={heading} />)}
        {isTextOnLeftAdj ? (
          <FocusAreaImage src={imageSrc} alt={heading} />)
          : (<FocusAreaText heading={heading} text={description} />)}
      </SimpleGrid>
    </Container>
  );
};

export default function FocusAreas({ children }) {
  return (
    <VStack width="100%">
      <Box width="100%" margin="-16px" height="16px" bgGradient="linear(to-b, transparent, white)" />
      {children}
    </VStack>
  );
}
