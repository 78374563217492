import React from 'react';
import {
  Box, Container, Flex, Image, SimpleGrid, Stack, Text, VStack, useBreakpointValue,
} from '@chakra-ui/react';

export const Strength = ({ title, text, imageSrc }) => (
  <Stack>
    <Flex align="center" justify="center">
      <Image
        src={`/images/${imageSrc}`}
        alt="test"
        objectFit="cover"
        rounded="full"
        w="100px"
        h="100px"
      />
    </Flex>
    <Text textStyle="h3" align="center" color="whiteAlpha.900">{title}</Text>
    <Flex align="center">
      <Container maxWidth={useBreakpointValue({ base: '58ch', md: '100ch' })}>
        <Text textStyle="detail" color="gray.400" align="justify">{text}</Text>
      </Container>
    </Flex>
  </Stack>
);

export default function KeyStrengths({ title, text, children }) {
  return (
    <VStack w="100%" backgroundColor="#16212b">
      <Box width="100%" margin="-16px" height="16px" bgGradient="linear(to-b, transparent, #16212b)" />
      <Container maxW="5xl" py={12}>
        <Stack spacing={4}>
          <Container maxW={useBreakpointValue({ base: '58ch', md: '69ch' })}>
            <Text textStyle="h2" casing="capitalize" color="whiteAlpha.900">{title}</Text>
            <Text textStyle="body" color="gray.400" marginTop="20px" textAlign="justify">
              {text}
            </Text>
          </Container>
          <Box p={4}>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>{children}</SimpleGrid>
          </Box>
        </Stack>
      </Container>
    </VStack>
  );
}
