import React from 'react';
import {
  Box,
  Collapse,
  Flex,
  Image,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import ContactForm from 'components/common/ContactForm';

const Logo = () => (
  <Flex>
    <Image
      src="/images/logo-white.png"
      alt="TLC Logo"
      objectFit="cover"
      rounded="md"
      w="205px"
      h="210px"
    />
  </Flex>
);

const Footer = React.forwardRef(({ backgroundImage }, ref) => {
  const { isOpen: isOpenThanks, onToggle: onToggleThanks } = useDisclosure();
  return (
    <VStack
      w="100%"
      backgroundImage={`url(/images/${backgroundImage})`}
      backgroundSize="cover"
    >
      <Box
        width="100%"
        margin="-8px"
        height="16px"
        marginBottom="-0.5rem"
        bgGradient="linear(to-b, #16212b, blackAlpha.700)"
      />
      <SimpleGrid
        backgroundColor="blackAlpha.700"
        columns={{ base: 1, md: 2 }}
        paddingTop={20}
        paddingBottom={20}
        spacing={8}
        w="full"
      >
        <VStack justify="center">
          <Logo />
          <Text textStyle="detail" color="white">P.O. Box 6073, Springfield, MO 65801</Text>
        </VStack>
        <VStack ref={ref} justify="center" m={4} minH={360}>
          <Collapse in={isOpenThanks} animateOpacity>
            <Text textStyle="h2" color="whiteAlpha.900">
              Thank you for your inquiry!
            </Text>
            <Text textStyle="body" color="whiteAlpha.900">One of our consultants will respond to you soon.</Text>
          </Collapse>
          <Collapse in={!isOpenThanks} animateOpacity>
            <Text textStyle="h2" align="center" casing="capitalize" color="whiteAlpha.900">Contact Us</Text>
            <ContactForm onSubmit={onToggleThanks} />
          </Collapse>
        </VStack>
      </SimpleGrid>
    </VStack>
  );
});

export default Footer;
