import React from 'react';
import {
  Flex,
  Stack,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import SimpleButton from 'components/common/SimpleButton';

export default function Hero({
  company, tagline, backgroundImage, contactRef,
}) {
  const executeScroll = () => contactRef.current.scrollIntoView({ behavior: 'smooth' });
  return (
    <Flex
      w="full"
      h="calc(100vh + 16px)"
      backgroundImage={`url(/images/${backgroundImage})`}
      backgroundSize="cover"
    >
      <VStack
        justify="center"
        px={useBreakpointValue({ base: 4, md: 8 })}
        bgGradient="linear(to-l, blackAlpha.800, transparent)"
      >
        <Stack align="flex-start" spacing={useBreakpointValue({ base: 4, md: 8 })}>
          <Text textStyle="body" casing="uppercase" color="white" fontSize="">
            {company}
          </Text>
          <Text textStyle="h1" casing="capitalize" color="white">
            {tagline}
          </Text>
          <SimpleButton text="Contact Us" onClick={executeScroll} />
        </Stack>
      </VStack>
    </Flex>
  );
}
