import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/source-sans-pro/300.css';
import '@fontsource/source-sans-pro/400.css';
import '@fontsource/source-sans-pro/700.css';
import { ChakraProvider } from '@chakra-ui/react';

import Amplify from '@aws-amplify/core';
import App from './App';
import customTheme from './utils/theme';

import config from './aws-exports';

Amplify.configure(config);

const rootElement = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={customTheme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  rootElement,
);
