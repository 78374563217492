import React from 'react';
import { Button } from '@chakra-ui/react';

export default function StandardButton({ text, onClick, isSubmit }) {
  return (
    <Button
      bg="blue.400"
      rounded="full"
      color="white"
      fontWeight={400}
      _hover={{ bg: 'blue.500' }}
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
    >
      {text}
    </Button>
  );
}
