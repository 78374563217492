import React, { createRef } from 'react';
import { Flex } from '@chakra-ui/react';
import Hero from 'components/Landing/Hero';
import FocusAreas, { FocusArea } from 'components/Landing/FocusAreas';
import KeyStrengths, { Strength } from 'components/Landing/KeyStrengths';
import Footer from 'components/Landing/Footer';

export default function Landing() {
  const contactRef = createRef(null);
  return (
    <Flex direction="column" align="center" m="0 auto">
      <Hero
        company="T. Larmer Consulting, LLC"
        tagline="Where structure meets innovation"
        backgroundImage="buildings.jpg"
        contactRef={contactRef}
      />
      <FocusAreas>
        <FocusArea
          heading="Business Strategy"
          description="We can help support your vision and initiatives with our
        experience in project management, business planning, strategy
        development, human resources, manufacturing systems, and various
        organizational infrastructure needs.  We’ll create a project plan that
        everyone understands, and then ensure it’s executed in a timely manner.
        Frequent updates and excellent communication help everyone stay on the
        same page throughout the project."
          imageSrc="/images/strategy.jpg"
          isTextOnLeft
        />
        <FocusArea
          heading="Full-Stack Development"
          description="Our proficiency and breadth of experience allow us to take
        a concept, and see it through to all phases of completion.  With a
        unique ability to communicate to a wide range of audiences and technical
        levels, we can develop backend databases through front-end UIs,
        independently or in collaboration with your team.  Focused on
        best-practice technology, serverless applications, and cloud provider
        opportunities to provide robust infrastructure with minimal upkeep, our
        solutions will be modern and elegant.  We can also partner with design
        firms for logo and branding needs, and incorporate those into your
        applications for fresh visual aesthetics."
          imageSrc="/images/development.jpg"
          isTextOnLeft={false}
        />
        <FocusArea
          heading="Data Science"
          description="Building end-to-end data science solutions - data
        engineering, analysis, modeling, and deployment - provides your
        business with a methodical, statistics-based approach to deriving
        meaningful information and making smarter decisions in a wide range of
        domains.  It all starts by collaborating with stakeholders to identify
        existing problems or inefficiencies that can be solved with data
        science.  We have a special interest in quantitative finance,
        but the same innovative, yet structured approach has unlimited
        possibilities and applications."
          imageSrc="/images/machine_learning.jpg"
          isTextOnLeft
        />
      </FocusAreas>
      <KeyStrengths
        title="We care about doing things right"
        text="Finding the right firm that aligns with your business&apos; needs
        and values can be challenging. We take the time to understand your
        goals, and will approach your project the same as we would our own.
        We respect your investment in your business, and us."
      >
        <Strength
          title="Comprehensive Solutions"
          text="We start by listening.  We want to learn about your
                business, your ideas, and the strengths and challenges you’re
                facing.  We will help conceptualize and design solutions
                specific for you, with the “big picture” in mind."
          imageSrc="globe.jpg"
        />
        <Strength
          title="Detailed Execution"
          text="We know the importance of a well-executed solution.
                Details are critical, and we’ll create a plan - start to finish
                - including robust testing and follow-through."
          imageSrc="detailed.jpg"
        />
        <Strength
          title="Integrity"
          text="We work with honesty and integrity, without exception.
                We believe our best work, and your best results, stem from a
                partnership with open communication, engagement, and respect."
          imageSrc="integrity.jpg"
        />
      </KeyStrengths>
      <Footer backgroundImage="footer.jpg" ref={contactRef} />
    </Flex>
  );
}
