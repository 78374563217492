/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createContactRequest = /* GraphQL */ `
  mutation CreateContactRequest(
    $input: CreateContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    createContactRequest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateContactRequest = /* GraphQL */ `
  mutation UpdateContactRequest(
    $input: UpdateContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    updateContactRequest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteContactRequest = /* GraphQL */ `
  mutation DeleteContactRequest(
    $input: DeleteContactRequestInput!
    $condition: ModelContactRequestConditionInput
  ) {
    deleteContactRequest(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      message
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
