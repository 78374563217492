import React, { useState } from 'react';
import {
  FormControl,
  Input,
  HStack,
  Stack,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { API } from 'aws-amplify';
import { createContactRequest } from 'graphql/mutations';
import SimpleButton from 'components/common/SimpleButton';

export default function ContactForm({ onSubmit }) {
  const toast = useToast();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });

  const handleContactFormSubmit = async (e) => {
    e.preventDefault();
    const {
      firstName, lastName, email, message,
    } = formState;
    if (firstName && lastName && email) {
      try {
        await API.graphql({
          query: createContactRequest,
          variables: {
            input: {
              firstName,
              lastName,
              email,
              message,
            },
          },
        });
        onSubmit();
      } catch (ex) {
        toast({
          title: 'Error',
          position: 'top-right',
          description: ex.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: 'There was an issue!',
        position: 'top-right',
        description: 'Please check that all the contact fields are filled out.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack spacing={4} w="full" maxW="md" p={6}>
      <form onSubmit={handleContactFormSubmit}>
        <VStack spacing={4}>
          <HStack spacing={8}>
            <FormControl id="firstName" isRequired>
              <Input
                placeholder="First name"
                _placeholder={{ color: 'gray.500' }}
                color="white"
                type="text"
                value={formState.firstName}
                onChange={(e) => setFormState({ ...formState, firstName: e.target.value })}
              />
            </FormControl>
            <FormControl id="lastName" isRequired>
              <Input
                placeholder="Last name"
                _placeholder={{ color: 'gray.500' }}
                color="white"
                type="text"
                value={formState.lastName}
                onChange={(e) => setFormState({ ...formState, lastName: e.target.value })}
              />
            </FormControl>
          </HStack>
          <FormControl id="email" isRequired>
            <Input
              placeholder="Email address"
              _placeholder={{ color: 'gray.500' }}
              color="white"
              type="email"
              value={formState.email}
              onChange={(e) => setFormState({ ...formState, email: e.target.value })}
            />
          </FormControl>
          <FormControl id="message">
            <Textarea
              placeholder="Message"
              _placeholder={{ color: 'gray.500' }}
              color="white"
              value={formState.message}
              onChange={(e) => setFormState({ ...formState, message: e.target.value })}
            />
          </FormControl>
          <Stack spacing={6}>
            <SimpleButton text="Submit" isSubmit />
          </Stack>
        </VStack>
      </form>
    </Stack>
  );
}
