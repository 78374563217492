import { extendTheme } from '@chakra-ui/react';

const customTheme = extendTheme({
  colors: {
    primary: {
      100: '#E5FCF1',
      200: '#27EF96',
      300: '#10DE82',
      400: '#0EBE6F',
      500: '#0CA25F',
      600: '#0A864F',
      700: '#086F42',
      800: '#075C37',
      900: '#064C2E',
    },
  },
  fonts: {
    heading: 'Source Sans Pro',
    body: 'Source Sans Pro',
  },
  textStyles: {
    h1: {
      fontSize: { base: '4xl', md: '5xl' },
      fontWeight: 300,
    },
    h2: {
      fontSize: { base: '3xl', md: '4xl' },
      fontWeight: 400,
    },
    h3: {
      fontSize: { base: 'lg', md: 'xl' },
      fontWeight: 600,
    },
    body: {
      fontSize: 'lg',
      fontWeight: 400,
    },
    detail: {
      fontSize: 'md',
      fontWeight: 400,
    },
  },
});

export default customTheme;
